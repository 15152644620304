import axios, { type AxiosInstance } from "axios";
import { parse } from "wellknown"

const runtimeConfig = useRuntimeConfig()

const location: string = runtimeConfig.public.apiInfra as string;
const epsg: string = "epsg=4326"
let httpClient: AxiosInstance;

let basePath: string = "/infra-api"

if (window.location.port == "") {
    httpClient = axios.create({
        baseURL: "http://" + window.location.host + basePath,
        timeout: 15000,
        withCredentials: false,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    });
} else {
    httpClient = axios.create({
        baseURL: location + basePath,
        withCredentials: false,
        timeout: 15000,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
}

export const INFRA  = {
    // TOLLS 
    async getTollStations(site: string): Promise<any[]> {
        let url = `/sites/${site}/tolls/stations`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getTollGates(site: string): Promise<any[]> {
        let url = `/sites/${site}/tolls/gates`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getTollGatesByStationId(site: string, stationId: string): Promise<any[]> {
        let url = `/sites/${site}/tolls/stations/${stationId}/gates`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getTollGate(site: string, gateId: string): Promise<any[]> {
        let url = `/sites/${site}/toll/gates/${gateId}`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getTollGateByStationId(site: string, stationId: string, gateId: string): Promise<any[]> {
        let url = `/sites/${site}/tolls/stations/${stationId}/gates/${gateId}`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getTollLanes(site: string): Promise<any[]> {
        let url = `/sites/${site}/tolls/lanes`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        let lanes : any[] = [];
        response.data.forEach((lane: any) => {
            lane.left_line = parse(lane.left_line);
            lane.right_line = parse(lane.right_line);
            lane.center_line = parse(lane.center_line);
            lane.polygon = parse(lane.polygon);
            lanes.push(lane);
        });
        return Promise.resolve(lanes);
    },
    async getTollLanesByStationId(site: string, stationId: string): Promise<any[]> {
        let url = `/sites/${site}/tolls/stations/${stationId}/lanes`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getTollLane(site: string, laneId: string): Promise<any[]> {
        let url = `/sites/${site}/tolls/lanes/${laneId}`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getTollLaneByStationId(site: string, stationId: string, laneId: string): Promise<any[]> {
        let url = `/sites/${site}/tolls/stations/${stationId}/lanes/${laneId}`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getTollZones(site: string): Promise<any[]> {
        let url = `/sites/${site}/tolls/zones`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getTollZonesByStationId(site: string, stationId: string): Promise<any[]> {
        let url = `/sites/${site}/tolls/stations/${stationId}/zones`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getTollZone(site: string, zoneId: string): Promise<any[]> {
        let url = `/sites/${site}/tolls/zones/${zoneId}`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getTollZoneByStationId(site: string, stationId: string, zoneId: string): Promise<any[]> {
        let url = `/sites/${site}/tolls/stations/${stationId}/zones/${zoneId}`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    // Cameras 
    async getCameras(site: string): Promise<any[]> {
        let url = `/sites/${site}/cameras`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        let cameras : any[] = [];
        response.data.forEach((camera: any) => {
            camera.position = parse(camera.position);
            camera.end_position = camera.end_position ? parse(camera.end_position): null;
            camera.lanes.forEach((lane: { linestring: any; polygon: any; }) => {
                lane.linestring = parse(lane.linestring);
                lane.polygon = parse(lane.polygon);
            });
            cameras.push(camera);
        cameras.sort((a, b) =>
            parseInt(a.ressource_id) - parseInt(b.ressource_id));
        });
        // console.log(cameras)
        return Promise.resolve(cameras);
    },
    async getCamera(site: string, cameraId: string): Promise<any[]> {
        let url = `/sites/${site}/cameras/${cameraId}`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        const camera = response.data
        if (camera){
            camera.position = parse(camera.position);
            camera.end_position = camera.end_position ? parse(camera.end_position): null;
            camera.lanes.forEach((lane: { linestring: any; polygon: any; }) => {
                lane.linestring = parse(lane.linestring);
                lane.polygon = parse(lane.polygon);
            })
        };
        return Promise.resolve(camera);
    },
    // UBRS 
    async getUbrs(site: string): Promise<any[]> {
        let url = `/sites/${site}/ubrs`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        let ubrs : any[] = [];
        response.data.forEach((ubr: any) => {
            ubr.position = parse(ubr.position);
            ubr.region_of_interest = parse(ubr.region_of_interest);
            ubrs.push(ubr);
        });
        return Promise.resolve(ubrs);
    },
    async getUbr(site: string, ubrId: string): Promise<any[]> {
        let url = `/sites/${site}/ubrs/${ubrId}`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    // BUS 
    async getBusStations(site: string): Promise<any[]> {
        let url = `/sites/${site}/bus/stations`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getBusBarriers(site: string): Promise<any[]> {
        let url = `/sites/${site}/bus/barriers`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getBusBarriersByStationId(site: string, stationId: string): Promise<any[]> {
        let url = `/sites/${site}/bus/barriers`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getBusBarrier(site: string, barrierId: string): Promise<any[]> {
        let url = `/sites/${site}/bus/barriers/${barrierId}`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getBusBarrierByStationId(site: string, stationId: string, barrierId: string): Promise<any[]> {
        let url = `/sites/${site}/bus/barriers/${barrierId}`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getBusLanes(site: string): Promise<any[]> {
        let url = `/sites/${site}/bus/lanes`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getBusLanesByStationId(site: string, stationId: string): Promise<any[]> {
        let url = `/sites/${site}/bus/lanes`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getBusLaneByStationId(site: string, stationId: string, laneId: string): Promise<any[]> {
        let url = `/sites/${site}/bus/lanes/${laneId}`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    // SITES 
    async getSites(): Promise<any[]> {
        let url = `/sites`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getSite(site: string): Promise<any[]> {
        let url = `/sites/${site}`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    //roads 
    async getRoadRoads(site: string): Promise<any[]> {
        let url = `/sites/${site}/road/roads`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        let roads : any[] = [];
        response.data.forEach((road: any) => {
            road.linestring = parse(road.linestring);
            road.polygon = parse(road.polygon);
            roads.push(road);
        });
        return Promise.resolve(roads);

    },
    async getRoadRoad(site: string, roadId: string): Promise<any[]> {
        let url = `/sites/${site}/road/roads/${roadId}`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getRoadLanes(site: string): Promise<any[]> {
        let url = `/sites/${site}/road/lanes`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        let lanes : any[] = [];
        response.data.forEach((lane: any) => {
            lane.left_line = parse(lane.left_line);
            lane.right_line = parse(lane.right_line);
            lane.center_line = parse(lane.center_line);
            lane.polygon = parse(lane.polygon);
            lanes.push(lane);
        });
        return Promise.resolve(lanes);
    },
    async getRoadLane(site: string, laneId: string): Promise<any[]> {
        let url = `/sites/${site}/road/lanes/${laneId}`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        return response.data;
    },
    async getRoadZones(site: string): Promise<any[]> {
        let url = `/sites/${site}/road/zones`;
        url += `?${epsg}`;
        let response = await httpClient.get(url);
        let zones : any[] = [];
        response.data.forEach((zone: any) => {
            zone.polygon = parse(zone.polygon);
            zones.push(zone);
        });
        return Promise.resolve(zones);
    },
    async getRoadZone(site: string, zoneId: string): Promise<any[]> {
        let url = `/sites/${site}/road/zones/${zoneId}`;
        url += `?${epsg}`;
        let zone : any = await httpClient.get(url);
        zone.polygon = parse(zone.polygon);
        return Promise.resolve(zone);
    },
    
}
